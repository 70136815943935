<template>
    <div id="create-usuario" class="animated fadeIn">
        <div class="row"><div class="col-md-12">
          <b-card>
<div slot="header">
            <strong>Novo usuário</strong>
          </div>
        <p><router-link :to="{ name: 'Listar Usuários' }">Listar usuários</router-link></p>

        <form v-on:submit.prevent="addUsuario">
            <div class="row">
                <div class="col-md-4">
                    <div v-if="!image">
                        <p>Nenhuma imagem</p>
                        <input type="file" @change="onFileChange">
                    </div>
                    <div v-else>
                        <img :src="image" class="img-thumbnail" />
                        <br><small>Utilize apenas imagens com até 100KB de tamanho</small>
                        <hr><button @click="removeImage">Trocar imagem</button>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label name="email"><strong>E-mail</strong></label>
                        <input type="email" class="form-control" v-model="usuario.email" id="email" required>
                    </div>

                    <div class="form-group">
                        <label name="password"><strong>Senha</strong></label>
                        <input type="password" class="form-control" v-model="usuario.password" id="password">
                    </div>

                    <div class="form-group">
                        <label name="password2"><strong>Redigite a senha</strong></label>
                        <input type="password" class="form-control" v-model="usuario.password2" id="password2">
                    </div>

                    <div class="form-check">
                        <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="usuario.is_active">
                        Ativar este usuário
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="container">
                    <div class="form-group">
                        <label name="first_name">Nome</label>
                        <input type="text" class="form-control" v-model="usuario.first_name" id="first_name">
                    </div>
                    <div class="form-group">
                        <label name="last_name">Sobrenome</label>
                        <input type="text" class="form-control" v-model="usuario.last_name" id="last_name">
                    </div>
                    <div class="form-group">
                        <label name="phone">Telefone</label>
                        <input type="tel" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control" v-model="usuario.phone" id="phone">
                    </div>
                    
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="container">
                      <div class="form-group">
                        <label name="area"><i v-if="loading" class="fa fa-spin fa-cog"></i>Empresa</label>
                        <b-form-select v-model="usuarioArea" :options="asAreas"></b-form-select>
                      </div>
                      <div class="form-group">
                        <label name="area"><i v-if="loading" class="fa fa-spin fa-cog"></i>Papel</label>
                        <b-form-select v-model="usuarioPapel" :options="osPapeis"></b-form-select>
                      </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary">Criar</button>
            </div>
        </form>
        </b-card>
        </div></div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
var CryptoJS = require('crypto-js')
export default {
  data () {
    return {
      usuario: {},
      ids: [],
      novoid: '',
      image: new Image(),
      contatos: [{value: null, label: ' - não selecionado -'}],
      empresas: [{value: null, label: ' - não selecionado -'}],
      usuarios: [{value: null, label: ' - não selecionado -'}],
      contatosAll: [],
      empresasAll: [],
      usuariosAll: [],
      usuarioArea: null,
      usuarioPapel: null,
      asAreas: [],
      osPapeis: [],
      loading: false
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('ADMINISTRATIVO'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.puxaListas()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    onFileChange: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0])
    },
    createImage: function (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (e) {
      this.image = ''
    },
    puxaListas: function () {
      this.loading = true
      this.listar('gp_Grupo', 'grp_mnemonico', 'gtipo_ID = 2').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.asAreas.push({value: response[i].grp_ID, text: response[i].grp_mnemonico})
          }
          this.listar('gp_Grupo', 'grp_mnemonico', 'gtipo_ID = 1').then(
            (response) => {
              for (var i = 0; i < response.length; i++) {
                this.osPapeis.push({value: response[i].grp_ID, text: response[i].grp_mnemonico})
              }
              this.loading = false
            },
            (response) => {}
          )
        },
        (response) => {}
      )
    },
    addUsuario: function () {
      if (this.usuario.email === '') {
        this.$notify({
          type: 'error',
          title: 'E-mail',
          text: 'O e-mail é obrigatório e precisa ser válido.'
        })
        return false
      }
      if (this.usuario.password === '') {
        this.$notify({
          type: 'error',
          title: 'Senha',
          text: 'Digite uma senha.'
        })
        return false
      }
      if (this.usuario.password !== this.usuario.password2) {
        this.$notify({
          type: 'error',
          title: 'Senha incorreta',
          text: 'A senha não bate com aquela que redigitou.'
        })
        return false
      }
      if (this.usuario.password && this.usuario.password.length < 6) {
        this.$notify({
          type: 'error',
          title: 'Senha inválida',
          text: 'A senha precisa conter pelo menos 6 caracteres.'
        })
        return false
      }
      this.usuario.usr_img = this.image
      this.inserir('user', {resource: [ this.usuario ]}, 'system', '1').then(
        (response) => {
          var id = response['id']
          if (id > 0) {
            this.$notify({
              type: 'success',
              title: 'Validando cadastro...',
              text: 'ID: ' + response['id']
            })
            var passwd = CryptoJS.AES.encrypt(this.usuario.usr_erp_password, 'gpcargo')
            this.inserir('gp_User', {resource: [
              {
                api_ID: id,
                usr_img: this.image
              }]}).then(
              (response) => {
                if (this.usuarioArea) {
                  var a = response
                  this.inserir('gp_User_Grupo', {resource: [{user_ID: a[Object.keys(a)[0]], grp_ID: this.usuarioArea}]}, '', '1').then(
                    (response) => {},
                    (response) => {}
                  )
                }
                if (this.usuarioPapel) {
                  this.inserir('gp_User_Grupo', {resource: [{user_ID: a[Object.keys(a)[0]], grp_ID: this.usuarioPapel}]}, '', '1').then(
                    (response) => {},
                    (response) => {}
                  )
                }
                this.$router.push('/admin/usuarios/listar')
              }, (response) => {
                this.$notify({
                  type: 'error',
                  title: 'Falha ao criar cadastro',
                  text: 'Confira no log da aplicação'
                })
                return false
              }
            )
          }
        }, (response) => {})
    }
  },
  components: {
    vSelect
  }
}
</script>